export const translations = {
  fr: {
    homepage: {
      title:
        "Cet complément vous permettra de signaler les e-mails de phishing à Elba. Si l'e-mail de phishing est un test Elba, cela augmentera votre score sur le classement Elba !",
      subtitle: 'Utilisez-moi pour taguer les e-mail qui vous paraissent suspects.',
    },
    reportCard: {
      sender: 'Expéditeur',
      subject: 'Sujet',
      reportEmail: "Signaler l'email",
      resultWhenFromElba: {
        title: "Bien joué, c'était un test d'elba !",
        description: 'Merci de votre vigilance.',
      },
      resultWhenNotFromElba: {
        title: "Cet email n'est pas un test d'elba…",
        description: `Faîtes suivre cet e-mail à votre équipe IT afin qu'ils puissent investiguer !`,
      },
      unexpectedError: {
        title: 'Une erreur inattendue est survenue',
        description: 'Contactez votre équipe IT.',
        try_again: 'Réessayer',
      },
      peekHeader: {
        title: 'Taguez les e-mails',
        description: 'Cliquez sur "afficher"',
      },
    },
  },
  en: {
    homepage: {
      title:
        'This add-in will allow you to report phishing emails to elba, if the phishing email is an elba test, this will increase your score on the elba leaderboard!',
      subtitle: 'Use me to flag suspicious e-mails.',
    },
    reportCard: {
      sender: 'Sender',
      subject: 'Subject',
      reportEmail: 'Report this email',
      resultWhenFromElba: {
        title: 'You rock, it was a test from elba!',
        description: 'Have a nice & safe day.',
      },
      resultWhenNotFromElba: {
        title: 'This email is not a test from elba…',
        description:
          'Make sure you forward this e-mail to your IT team so that they can investigate it!',
      },
      unexpectedError: {
        title: 'An unexpected error occured',
        description: 'Please contact your IT Team.',
        try_again: 'Try again',
      },
      peekHeader: {
        title: 'Flag e-mails',
        description: 'Click on "view" to start flagging',
      },
    },
  },
} as const;
