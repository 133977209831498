import type React from 'react';
import { forwardRef as forwardReactRef } from 'react';

/**
 * Shamefully copied from @chakra-ui
 */

// ref: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/system/src/system.types.tsx

export type As<Props = any> = React.ElementType<Props>;

export type ComponentPropsWithAs<T extends As> = React.ComponentPropsWithoutRef<T> & {
  as?: As;
};

type OmitCommonProps<Target, OmitAdditionalProps extends keyof any = never> = Omit<
  Target,
  'as' | OmitAdditionalProps
>;

type RightJoinProps<SourceProps extends object, OverrideProps extends object> = OmitCommonProps<
  SourceProps,
  keyof OverrideProps
> &
  OverrideProps;

type MergeWithAs<
  ComponentProps extends object,
  AsProps extends object,
  AdditionalProps extends object,
  AsComponent extends As = As,
> = RightJoinProps<ComponentProps, AdditionalProps> &
  RightJoinProps<AsProps, AdditionalProps> & {
    as?: AsComponent;
  };

export type ComponentWithAs<Component extends As, Props extends object> = {
  <AsComponent extends As = Component>(
    props: MergeWithAs<
      React.ComponentProps<Component>,
      React.ComponentProps<AsComponent>,
      Props,
      AsComponent
    >
  ): JSX.Element;

  displayName?: string;
  propTypes?: React.WeakValidationMap<any>;
  contextTypes?: React.ValidationMap<any>;
  defaultProps?: Partial<any>;
  id?: string;
};

// ref: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/system/src/forward-ref.tsx

export function forwardRefWithAs<Props extends object, Component extends As>(
  component: React.ForwardRefRenderFunction<
    any,
    RightJoinProps<ComponentPropsWithAs<Component>, Props> & {
      as?: As;
    }
  >
) {
  return forwardReactRef(component) as unknown as ComponentWithAs<Component, Props>;
}
