import clsx from 'clsx';
import React from 'react';
import { forwardRefWithAs, type ComponentPropsWithAs } from './polymorphism';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-md'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl';

export type TypographyProps = ComponentPropsWithAs<'p'> & {
  children: React.ReactNode;
  variant?: TypographyVariant;
  color?:
    | 'black'
    | 'darkGray'
    | 'gray'
    | 'green'
    | 'inherit'
    | 'lightGray'
    | 'orange'
    | 'yellow'
    | 'purple'
    | 'red'
    | 'white';
};

const VARIANT_COMPONENT_MAPPING: Partial<Record<TypographyVariant, React.ElementType>> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;

const VARIANT_COLOR_MAPPING: Record<TypographyVariant, string> = {
  h1: 'text-gray-900',
  h2: 'text-gray-900',
  h3: 'text-gray-900',
  h4: 'text-gray-900',
  h5: 'text-gray-900',
  h6: 'text-gray-900',
  'text-xs': 'text-gray-500',
  'text-sm': 'text-gray-500',
  'text-base': 'text-gray-500',
  'text-md': 'text-gray-500',
  'text-lg': 'text-gray-500',
  'text-xl': 'text-gray-500',
  'text-2xl': 'text-gray-500',
} as const;

export const Typography = forwardRefWithAs<TypographyProps, 'p'>(
  ({ children, variant = 'text-base', className, color, as, ...rest }, ref) => {
    const Component = as || VARIANT_COMPONENT_MAPPING[variant] || 'p';
    const colorClassOverride =
      color &&
      {
        black: 'text-gray-900',
        darkGray: 'text-gray-700',
        gray: 'text-gray-500',
        green: 'text-green-700',
        inherit: 'text-inherit',
        lightGray: 'text-gray-300',
        orange: 'text-blue-500',
        red: 'text-red-500',
        white: 'text-white',
        yellow: 'text-orange-1000',
        purple: 'text-purple-700',
      }[color];

    const colorClass = colorClassOverride || VARIANT_COLOR_MAPPING[variant];

    return (
      <Component
        ref={ref}
        {...rest}
        className={clsx(
          'm-0', // this is to prevent margin from being inherited from global styles when previewing phishing scenarios
          colorClass,
          {
            h1: 'font-title text-h1 font-medium',
            h2: 'font-title text-h2 font-medium',
            h3: 'font-title text-h3 font-medium',
            h4: 'font-title text-h4 font-medium',
            h5: 'font-title text-h5 font-medium',
            h6: 'font-title text-h6 font-medium',
            'text-xs': 'font-body text-xs font-normal',
            'text-sm': 'font-body text-sm font-normal',
            'text-base': 'font-body text-base font-normal',
            'text-md': 'font-body text-md font-normal',
            'text-lg': 'font-body text-lg font-medium',
            'text-xl': 'font-body text-xl font-bold',
            'text-2xl': 'font-body text-2xl font-bold',
          }[variant],
          className
        )}>
        {children}
      </Component>
    );
  }
);

Typography.displayName = 'Typography';
