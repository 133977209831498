import * as React from 'react';
import { reportPhishingEmail } from './data';
import { translations } from './translations';
import { Button } from './ui/Button';
import { Typography } from './ui/Typography';

type State = 'elba_test' | 'real_phishing' | 'error' | 'idle';

const App = () => {
  const [state, setState] = React.useState<State>('idle');

  return (
    <div className="min-h-screen space-y-4 p-4">
      <View state={state} setState={setState} />
    </div>
  );
};

export default App;

const View = ({ state, setState }: { state: State; setState: (newState: State) => void }) => {
  const myLanguage = Office.context.displayLanguage.split('-')[0] === 'fr' ? 'fr' : 'en';
  const texts = translations[myLanguage];
  const [isReporting, setIsReporting] = React.useState(false);

  const reportEmail = async () => {
    setIsReporting(true);
    try {
      const result = await reportPhishingEmail();
      setState(result);
    } catch (e) {
      console.error(e);
      setState('error');
    } finally {
      setIsReporting(false);
    }
  };

  if (state === 'idle') {
    return (
      <>
        <div>
          <Typography variant="text-xs">{texts.reportCard.subject}</Typography>
          <Typography variant="h6">
            {Office.context.mailbox.item?.subject || 'No Subject'}
          </Typography>
        </div>

        <div>
          <Typography variant="text-xs">{texts.reportCard.sender}</Typography>
          <Typography variant="h6">
            {Office.context.mailbox.item?.sender.displayName || 'Unknown Sender'}
          </Typography>
        </div>
        <Button onClick={reportEmail} isLoading={isReporting}>
          {texts.reportCard.reportEmail}
        </Button>
      </>
    );
  }

  if (state === 'error') {
    return (
      <>
        <div className="space-y-2">
          <Typography variant="h5">{texts.reportCard.unexpectedError.title}</Typography>
          <Typography>{texts.reportCard.unexpectedError.description}</Typography>
        </div>

        <Button onClick={() => setState('idle')}>
          {texts.reportCard.unexpectedError.try_again}
        </Button>
      </>
    );
  }

  if (state === 'elba_test') {
    return (
      <>
        <img
          src="https://d28zas9djvgpvy.cloudfront.net/Guardian/happy.gif"
          width={200}
          height={200}
          alt="Animation"
          className="mx-auto"
        />
        <div className="space-y-2">
          <Typography variant="h5">{texts.reportCard.resultWhenFromElba.title}</Typography>
          <Typography>{texts.reportCard.resultWhenFromElba.description}</Typography>
        </div>
      </>
    );
  }

  if (state === 'real_phishing') {
    return (
      <>
        <img
          src="https://d28zas9djvgpvy.cloudfront.net/Guardian/investigating.gif"
          width={200}
          height={200}
          alt="Animation"
          className="mx-auto"
        />
        <div className="space-y-2">
          <Typography variant="h5">{texts.reportCard.resultWhenNotFromElba.title}</Typography>
          <Typography>{texts.reportCard.resultWhenNotFromElba.description}</Typography>
        </div>
      </>
    );
  }

  return exhaustive(state);
};

function exhaustive(arg: never): never {
  throw new Error(`non exhaustive: ${arg} not handled`);
}
