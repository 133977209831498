const REPORTING_URLS = [
  `https://admin.eu.elba.security/api/phishing-webhook/report-email`,
  `https://admin.us.elba.security/api/phishing-webhook/report-email`,
];
const AUTH_SECRET = 'Ne1y1HwSEwMsQxQ744S7EV5941VY31X4';

export async function reportPhishingEmail() {
  const messageId = Office.context.mailbox.item?.internetMessageId;
  const body = await getEmailBodyTextAsync();
  const sender = Office.context.mailbox.item?.sender.displayName;
  const subject = Office.context.mailbox.item?.subject;
  const recipientEmail = await getRecipientEmail();

  const results = await Promise.allSettled(
    REPORTING_URLS.map(async (url) => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${AUTH_SECRET}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailClient: 'outlook',
          emailId: messageId,
          body: body,
          recipient: recipientEmail,
          sender,
          subject,
        }),
      });

      const data = await response.json();
      if (response.status === 200) {
        if (data.wasAnElbaTest) {
          return 'elba_test';
        }
        return 'real_phishing';
      }
      throw new Error('Request failed');
    })
  );

  const allErrors = results.every((result) => {
    return result.status === 'rejected';
  });

  if (allErrors) {
    throw new Error('All Requests failed');
  }

  // check if the email was an elba test on one of the environments
  const wasAnElbaTest = results.some((result) => {
    if (result.status === 'fulfilled') {
      return result.value === 'elba_test';
    }
    return false;
  });

  if (wasAnElbaTest) {
    return 'elba_test';
  }
  return 'real_phishing';
}

function getEmailBodyTextAsync() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item?.body.getAsync(Office.CoercionType.Html, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error.message);
      }
    });
  });
}

//Get access token for current user info
function getRecipientEmail() {
  return new Promise(function (resolve, reject) {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const tokenInfo = JSON.parse(parseJwt(result.value));
        resolve(tokenInfo.smtp);
      } else {
        reject('Error obtaining access token');
      }
    });
  });
}

//Parse JWT
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (!base64) {
    throw new Error('Invalid token');
  }
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return jsonPayload;
}
