import { cva, type VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { Loader, type LucideIcon } from 'lucide-react';
import React from 'react';
import { forwardRefWithAs, type ComponentPropsWithAs } from './polymorphism';

const button = cva(
  'font-title relative flex cursor-pointer items-center justify-center rounded ring-0 transition focus-visible:outline-none data-[is-enabled=false]:cursor-not-allowed data-[is-enabled=false]:opacity-50 data-[is-enabled=true]:focus-visible:ring-2',
  {
    variants: {
      variant: {
        primary: 'text-h5 h-10 gap-x-2 px-4',
        secondary: 'text-h5 h-10 gap-x-2 border px-4',
        minimal: 'text-h6 gap-x-1',
      },
      color: {
        default: 'ring-blue-200',
        warning: 'ring-red-200',
        inverted: 'ring-blue-200',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        color: 'default',
        className: 'bg-black text-white data-[is-enabled=true]:hover:bg-gray-600',
      },
      {
        variant: 'secondary',
        color: 'default',
        className:
          'border-gray-200 text-gray-900 data-[is-enabled=true]:hover:border-gray-400 data-[is-enabled=true]:hover:text-gray-600',
      },
      {
        variant: 'minimal',
        color: 'default',
        className: 'text-gray-900 data-[is-enabled=true]:hover:text-gray-600',
      },
      {
        variant: 'primary',
        color: 'warning',
        className: 'bg-red-900 text-white data-[is-enabled=true]:hover:bg-red-800',
      },
      {
        variant: 'secondary',
        color: 'warning',
        className:
          'border-red-900 bg-white text-red-900 data-[is-enabled=true]:hover:border-red-800 data-[is-enabled=true]:hover:text-red-800',
      },
      {
        variant: 'minimal',
        color: 'warning',
        className: 'text-red-900 data-[is-enabled=true]:hover:text-red-800',
      },
      {
        variant: ['primary', 'secondary'],
        color: 'inverted',
        className: 'border-0 bg-white text-gray-900 data-[is-enabled=true]:hover:bg-gray-200',
      },
      {
        variant: 'minimal',
        color: 'inverted',
        className: 'text-white data-[is-enabled=true]:hover:text-gray-200',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      color: 'default',
    },
  }
);

export type ButtonProps = ComponentPropsWithAs<'button'> &
  VariantProps<typeof button> & {
    icon?: LucideIcon;
    isLoading?: boolean;
    shouldIconSpin?: boolean;
    children?: string;
  };

export const Button = forwardRefWithAs<ButtonProps, 'button'>(
  (
    {
      icon: Icon,
      className,
      color,
      variant,
      isLoading = false,
      disabled = false,
      shouldIconSpin,
      children,
      as: Component = 'button',
      ...rest
    },
    ref
  ) => {
    if (!Icon && !children) {
      throw new Error('Button must have either an icon, a child or both.');
    }

    const isIconOnly = Icon && !children;

    return (
      <Component
        ref={ref}
        className={clsx(
          button({ variant, color }),
          {
            'aspect-square px-0': isIconOnly && variant !== 'minimal',
          },
          className
        )}
        data-is-enabled={!disabled && !isLoading}
        disabled={disabled || isLoading}
        {...rest}>
        {Icon && (
          <Icon
            size={16}
            className={clsx('flex-shrink-0 text-inherit', shouldIconSpin && 'animate-spin-slow')}
          />
        )}
        {children && <span className="truncate">{children}</span>}
        {!isIconOnly && isLoading && (
          <Loader
            role="progressbar"
            data-testid="elba-loader"
            size={14}
            className="animate-spin-slow flex-shrink-0 text-inherit"
          />
        )}
      </Component>
    );
  }
);

Button.displayName = 'Button';
